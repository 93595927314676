var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-card",
        { attrs: { "header-tag": "header", "footer-tag": "footer" } },
        [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c("strong", [_vm._v(" Filtros ")]),
              _c(
                "b-button",
                {
                  staticClass: "text-white float-right",
                  attrs: { variant: "primary", size: "xs" },
                  on: {
                    click: function($event) {
                      return _vm.filtrosAvancados()
                    }
                  }
                },
                [_vm._v("Avançado")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "p-1 mt-2" },
            [
              _c(
                "b-row",
                { staticClass: "mb-1" },
                [
                  _c(
                    "b-col",
                    { staticClass: "d-md-block", attrs: { sm: "12", md: "4" } },
                    [
                      _c(
                        "b-button-toolbar",
                        { staticClass: "float-left" },
                        [
                          _c(
                            "b-form-radio-group",
                            {
                              staticClass: "mr-3",
                              attrs: {
                                buttons: "",
                                "button-variant": "outline-primary",
                                name: "radiosBtn"
                              },
                              on: { change: _vm.changePeriodo },
                              model: {
                                value: _vm.filtro.fields.periodo,
                                callback: function($$v) {
                                  _vm.$set(_vm.filtro.fields, "periodo", $$v)
                                },
                                expression: "filtro.fields.periodo"
                              }
                            },
                            [
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "mx-0",
                                  attrs: { value: "Hoje" }
                                },
                                [_vm._v("Hoje")]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "mx-0",
                                  attrs: { value: "Ontem" }
                                },
                                [_vm._v("Ontem")]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "mx-0",
                                  attrs: { value: "Semana" }
                                },
                                [_vm._v("Semana")]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "mx-0",
                                  attrs: { value: "Mes" }
                                },
                                [_vm._v("Mês")]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "mx-0",
                                  attrs: { value: "Periodo" }
                                },
                                [_vm._v("Período")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("b-col", { attrs: { sm: "12", md: "8" } }, [
                    _c(
                      "div",
                      { staticClass: "custom-control custom-checkbox pt-1" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filtro.fields.desconsiderarPecas,
                              expression: "filtro.fields.desconsiderarPecas"
                            }
                          ],
                          staticClass: "custom-control-input",
                          attrs: {
                            type: "checkbox",
                            id: "chkDesconsiderarPecas"
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.filtro.fields.desconsiderarPecas
                            )
                              ? _vm._i(
                                  _vm.filtro.fields.desconsiderarPecas,
                                  null
                                ) > -1
                              : _vm.filtro.fields.desconsiderarPecas
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.filtro.fields.desconsiderarPecas,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.filtro.fields,
                                      "desconsiderarPecas",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.filtro.fields,
                                      "desconsiderarPecas",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.filtro.fields,
                                  "desconsiderarPecas",
                                  $$c
                                )
                              }
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "custom-control-label",
                            attrs: { for: "chkDesconsiderarPecas" }
                          },
                          [_vm._v('Desconsiderar devoluções em "Peças"')]
                        )
                      ]
                    )
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mb-1" },
                [
                  _c(
                    "b-col",
                    { staticClass: "pr-md-0 mb-1 mb-md-0", attrs: { sm: "4" } },
                    [
                      _c(
                        "multiselect",
                        {
                          attrs: {
                            options: _vm.filtro.listas.gestao,
                            placeholder: "Todas as Gestões",
                            multiple: true,
                            "close-on-select": false,
                            "clear-on-select": true,
                            "show-labels": false,
                            label: "text",
                            "track-by": "value"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "selection",
                              fn: function(ref) {
                                var values = ref.values
                                var search = ref.search
                                var isOpen = ref.isOpen
                                return [
                                  values.length == 1 && !isOpen
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(values.length) +
                                            " opção selecionada"
                                        )
                                      ])
                                    : _vm._e(),
                                  values.length >= 2 && !isOpen
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(values.length) +
                                            " opções selecionadas"
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.filtro.fields.gestao,
                            callback: function($$v) {
                              _vm.$set(_vm.filtro.fields, "gestao", $$v)
                            },
                            expression: "filtro.fields.gestao"
                          }
                        },
                        [
                          _c("template", { slot: "clear" }, [
                            _vm.filtro.fields.gestao.length
                              ? _c("div", {
                                  staticClass: "multiselect__clear",
                                  on: {
                                    mousedown: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.clearAll("gestao")
                                    }
                                  }
                                })
                              : _vm._e()
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "pr-md-0 mb-1 mb-md-0", attrs: { sm: "4" } },
                    [
                      _c(
                        "multiselect",
                        {
                          attrs: {
                            options:
                              _vm.filtro.listas.centresFiltradosPorGestao,
                            placeholder: "Todas as Lojas",
                            multiple: true,
                            "close-on-select": false,
                            "clear-on-select": true,
                            "show-labels": false,
                            label: "text",
                            "track-by": "value"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "selection",
                              fn: function(ref) {
                                var values = ref.values
                                var search = ref.search
                                var isOpen = ref.isOpen
                                return [
                                  values.length == 1 && !isOpen
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(values.length) +
                                            " opção selecionada"
                                        )
                                      ])
                                    : _vm._e(),
                                  values.length >= 2 && !isOpen
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(values.length) +
                                            " opções selecionadas"
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.filtro.fields.centres,
                            callback: function($$v) {
                              _vm.$set(_vm.filtro.fields, "centres", $$v)
                            },
                            expression: "filtro.fields.centres"
                          }
                        },
                        [
                          _c("template", { slot: "clear" }, [
                            _vm.filtro.fields.centres.length
                              ? _c("div", {
                                  staticClass: "multiselect__clear",
                                  on: {
                                    mousedown: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.clearAll("centres")
                                    }
                                  }
                                })
                              : _vm._e()
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-1 mb-md-0", attrs: { sm: "4" } },
                    [
                      _c(
                        "multiselect",
                        {
                          attrs: {
                            options: _vm.filtro.listas.formaVenda,
                            placeholder: "Todas as Formas de Venda",
                            multiple: true,
                            "close-on-select": false,
                            "clear-on-select": true,
                            "show-labels": false,
                            label: "text",
                            "track-by": "value"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "selection",
                              fn: function(ref) {
                                var values = ref.values
                                var search = ref.search
                                var isOpen = ref.isOpen
                                return [
                                  values.length == 1 && !isOpen
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(values.length) +
                                            " opção selecionada"
                                        )
                                      ])
                                    : _vm._e(),
                                  values.length >= 2 && !isOpen
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(values.length) +
                                            " opções selecionadas"
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.filtro.fields.formaVenda,
                            callback: function($$v) {
                              _vm.$set(_vm.filtro.fields, "formaVenda", $$v)
                            },
                            expression: "filtro.fields.formaVenda"
                          }
                        },
                        [
                          _c("template", { slot: "clear" }, [
                            _vm.filtro.fields.formaVenda.length
                              ? _c("div", {
                                  staticClass: "multiselect__clear",
                                  on: {
                                    mousedown: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.clearAll("formaVenda")
                                    }
                                  }
                                })
                              : _vm._e()
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mb-1" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "pr-md-0 pr-1 mb-1 mb-md-0",
                      attrs: { cols: "6", sm: "6", md: "4" }
                    },
                    [
                      _c("b-form-input", {
                        attrs: { plain: true, type: "date" },
                        on: { change: _vm.dataChanged },
                        model: {
                          value: _vm.filtro.fields.dataIni,
                          callback: function($$v) {
                            _vm.$set(_vm.filtro.fields, "dataIni", $$v)
                          },
                          expression: "filtro.fields.dataIni"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "pr-md-0 pl-1 pl-md-3 mb-1 mb-md-0",
                      attrs: { cols: "6", sm: "6", md: "4" }
                    },
                    [
                      _c("b-form-input", {
                        attrs: { plain: true, type: "date" },
                        on: { change: _vm.dataChanged },
                        model: {
                          value: _vm.filtro.fields.dataFim,
                          callback: function($$v) {
                            _vm.$set(_vm.filtro.fields, "dataFim", $$v)
                          },
                          expression: "filtro.fields.dataFim"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "pr-1",
                      attrs: { cols: "6", sm: "6", md: "1" }
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          plain: true,
                          options: _vm.filtro.listas.limits
                        },
                        model: {
                          value: _vm.filtro.fields.limit,
                          callback: function($$v) {
                            _vm.$set(_vm.filtro.fields, "limit", $$v)
                          },
                          expression: "filtro.fields.limit"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "pt-2 pl-md-0",
                      attrs: { cols: "6", sm: "6", md: "2" }
                    },
                    [
                      _c("label", { staticClass: "mb-0 text-nowrap" }, [
                        _vm._v("mais vendidos")
                      ])
                    ]
                  ),
                  !_vm.filtro.filtrosAvancados
                    ? _c(
                        "b-col",
                        { attrs: { sm: "1" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "my-2 my-sm-0 text-white",
                              class: { "btn-block": _vm.$mq === "sm" },
                              attrs: { variant: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.search()
                                }
                              }
                            },
                            [_c("i", { staticClass: "fa fa-search" })]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.filtro.filtrosAvancados
                ? _c(
                    "b-row",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "pr-md-0 pr-1 mb-1",
                          attrs: { cols: "6", sm: "6", md: "4" }
                        },
                        [
                          _c(
                            "multiselect",
                            {
                              attrs: {
                                options: _vm.filtro.listas.fornecedores,
                                placeholder: "Todos os Fornecedores",
                                multiple: true,
                                "close-on-select": false,
                                "clear-on-select": true,
                                "show-labels": false,
                                label: "text",
                                "track-by": "value"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "selection",
                                    fn: function(ref) {
                                      var values = ref.values
                                      var search = ref.search
                                      var isOpen = ref.isOpen
                                      return [
                                        values.length == 1 && !isOpen
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(values.length) +
                                                  " opção selecionada"
                                              )
                                            ])
                                          : _vm._e(),
                                        values.length >= 2 && !isOpen
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(values.length) +
                                                  " opções selecionadas"
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1659997751
                              ),
                              model: {
                                value: _vm.filtro.fields.fornecedores,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.filtro.fields,
                                    "fornecedores",
                                    $$v
                                  )
                                },
                                expression: "filtro.fields.fornecedores"
                              }
                            },
                            [
                              _c("template", { slot: "clear" }, [
                                _vm.filtro.fields.fornecedores.length
                                  ? _c("div", {
                                      staticClass: "multiselect__clear",
                                      on: {
                                        mousedown: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.clearAll("fornecedores")
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "pr-md-0 pr-1 mb-1",
                          attrs: { cols: "6", sm: "6", md: "4" }
                        },
                        [
                          _c(
                            "multiselect",
                            {
                              attrs: {
                                options: _vm.filtro.listas.grupos,
                                placeholder: "Todos os Grupos",
                                multiple: true,
                                "close-on-select": false,
                                "clear-on-select": true,
                                "show-labels": false,
                                label: "text",
                                "track-by": "value"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "selection",
                                    fn: function(ref) {
                                      var values = ref.values
                                      var search = ref.search
                                      var isOpen = ref.isOpen
                                      return [
                                        values.length == 1 && !isOpen
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(values.length) +
                                                  " opção selecionada"
                                              )
                                            ])
                                          : _vm._e(),
                                        values.length >= 2 && !isOpen
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(values.length) +
                                                  " opções selecionadas"
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1659997751
                              ),
                              model: {
                                value: _vm.filtro.fields.grupos,
                                callback: function($$v) {
                                  _vm.$set(_vm.filtro.fields, "grupos", $$v)
                                },
                                expression: "filtro.fields.grupos"
                              }
                            },
                            [
                              _c("template", { slot: "clear" }, [
                                _vm.filtro.fields.grupos.length
                                  ? _c("div", {
                                      staticClass: "multiselect__clear",
                                      on: {
                                        mousedown: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.clearAll("grupos")
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "mb-1",
                          attrs: { cols: "6", sm: "6", md: "4" }
                        },
                        [
                          _c(
                            "multiselect",
                            {
                              attrs: {
                                options: _vm.filtro.listas.subgrupos,
                                placeholder: "Todos os Subgrupos",
                                multiple: true,
                                "close-on-select": false,
                                "clear-on-select": true,
                                "show-labels": false,
                                label: "text",
                                "track-by": "value"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "selection",
                                    fn: function(ref) {
                                      var values = ref.values
                                      var search = ref.search
                                      var isOpen = ref.isOpen
                                      return [
                                        values.length == 1 && !isOpen
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(values.length) +
                                                  " opção selecionada"
                                              )
                                            ])
                                          : _vm._e(),
                                        values.length >= 2 && !isOpen
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(values.length) +
                                                  " opções selecionadas"
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1659997751
                              ),
                              model: {
                                value: _vm.filtro.fields.subgrupos,
                                callback: function($$v) {
                                  _vm.$set(_vm.filtro.fields, "subgrupos", $$v)
                                },
                                expression: "filtro.fields.subgrupos"
                              }
                            },
                            [
                              _c("template", { slot: "clear" }, [
                                _vm.filtro.fields.subgrupos.length
                                  ? _c("div", {
                                      staticClass: "multiselect__clear",
                                      on: {
                                        mousedown: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.clearAll("subgrupos")
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "pr-md-0 pr-1 mb-1",
                          attrs: { cols: "6", sm: "6", md: "4" }
                        },
                        [
                          _c(
                            "multiselect",
                            {
                              attrs: {
                                options: _vm.filtro.listas.vendedores,
                                placeholder: "Todos os Vendedores",
                                multiple: true,
                                "close-on-select": false,
                                "clear-on-select": true,
                                "show-labels": false,
                                label: "text",
                                "track-by": "value"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "selection",
                                    fn: function(ref) {
                                      var values = ref.values
                                      var search = ref.search
                                      var isOpen = ref.isOpen
                                      return [
                                        values.length == 1 && !isOpen
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(values.length) +
                                                  " opção selecionada"
                                              )
                                            ])
                                          : _vm._e(),
                                        values.length >= 2 && !isOpen
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(values.length) +
                                                  " opções selecionadas"
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1659997751
                              ),
                              model: {
                                value: _vm.filtro.fields.vendedores,
                                callback: function($$v) {
                                  _vm.$set(_vm.filtro.fields, "vendedores", $$v)
                                },
                                expression: "filtro.fields.vendedores"
                              }
                            },
                            [
                              _c("template", { slot: "clear" }, [
                                _vm.filtro.fields.vendedores.length
                                  ? _c("div", {
                                      staticClass: "multiselect__clear",
                                      on: {
                                        mousedown: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.clearAll("vendedores")
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "1" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "my-2 my-sm-0 text-white",
                              class: { "btn-block": _vm.$mq === "sm" },
                              attrs: { variant: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.search()
                                }
                              }
                            },
                            [_c("i", { staticClass: "fa fa-search" })]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _c("c-table", {
        attrs: {
          "table-data": _vm.items,
          fields: _vm.fields,
          striped: "",
          small: "",
          caption: "Produtos Mais Vendidos",
          "per-page": 50
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }